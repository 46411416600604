<template>
    <Link href="/" class="-m-1.5 p-1.5">
        <span class="sr-only">{{ appName }}</span>
        <img class="h-12 w-auto" :src="logoSrc" :alt="appName + ' logo'" />
    </Link>
</template>

<script setup>
import { Link, usePage } from "@inertiajs/vue3";
import { computed } from "vue";

const props = defineProps({
    dark: {
        type: Boolean,
        default: false,
    },
});

const page = usePage();
const appName = computed(() => page.props.appName);

const logoSrc = computed(() =>
    props.dark ? page.props.logoUrl.dark : page.props.logoUrl.light,
);
</script>
