<script setup>
import Header from "@/Components/Guest/Header.vue";
import Footer from "@/Components/Guest/Footer.vue";
import { computed } from "vue";
import AppHead from "@/Components/AppHead.vue";

const props = defineProps({
    dark: {
        type: Boolean,
        default: false,
    },
    absoluteHeader: {
        type: Boolean,
        default: false,
    },
});
const headerClass = computed(() => {
    return props.absoluteHeader ? "absolute -mt-24" : "";
});
</script>

<template>
    <AppHead />
    <div class="bg-white">
        <Header :dark="props.dark" :class="headerClass" />
        <main>
            <slot />
        </main>
        <Footer />
    </div>
</template>
