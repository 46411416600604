<template>
    <footer class="bg-gray-950" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 lg:px-8">
            <!-- Top Footer -->
            <div class="space-y-8">
                <img class="h-16" :src="logoUrl.dark" :alt="appName" />
                <p class="text-md leading-6 text-gray-300">
                    Connecting Dubai's best remote talent with top companies
                    worldwide.
                </p>
                <div class="flex space-x-6">
                    <a
                        v-for="item in navigation.social"
                        :key="item.name"
                        :href="item.href"
                        class="text-gray-500 hover:text-gray-400"
                    >
                        <span class="sr-only">{{ item.name }}</span>
                        <component
                            :is="item.icon"
                            class="h-6 w-6"
                            aria-hidden="true"
                        />
                    </a>
                </div>
            </div>

            <!-- Navigation -->
            <div
                class="mt-16 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8"
            >
                <!-- Jobs by Industry -->
                <div>
                    <h3
                        class="text-xl font-gothic font-semibold leading-6 tracking-wide text-white"
                    >
                        Jobs by Industry
                    </h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li
                            v-for="item in navigation.category"
                            :key="item.name"
                        >
                            <Link
                                :href="item.href"
                                class="text-md leading-6 text-gray-300 hover:text-white"
                                >{{ item.name }}</Link
                            >
                        </li>
                    </ul>
                </div>

                <!-- Jobs by Skill -->
                <div>
                    <h3
                        class="text-xl font-gothic font-semibold leading-6 tracking-wide text-white"
                    >
                        Jobs by Skill
                    </h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li v-for="item in navigation.tag" :key="item.name">
                            <Link
                                :href="item.href"
                                class="text-md leading-6 text-gray-300 hover:text-white"
                                >{{ item.name }}</Link
                            >
                        </li>
                    </ul>
                </div>

                <!-- Jobs by City and Jobs by Type -->
                <div>
                    <h3
                        class="text-xl font-gothic font-semibold leading-6 tracking-wide text-white"
                    >
                        Jobs by City
                    </h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li v-for="item in navigation.city" :key="item.name">
                            <Link
                                :href="item.href"
                                class="text-md leading-6 text-gray-300 hover:text-white"
                                >{{ item.name }}</Link
                            >
                        </li>
                    </ul>
                    <h3
                        class="mt-10 text-xl font-gothic font-semibold leading-6 tracking-wide text-white"
                    >
                        Jobs by Type
                    </h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li v-for="item in navigation.type" :key="item.name">
                            <Link
                                :href="item.href"
                                class="text-md leading-6 text-gray-300 hover:text-white"
                                >{{ item.name }}</Link
                            >
                        </li>
                    </ul>
                </div>

                <!-- Company and Legal -->
                <div>
                    <h3
                        class="text-xl font-gothic font-semibold leading-6 tracking-wide text-white"
                    >
                        Company
                    </h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li v-for="item in navigation.company" :key="item.name">
                            <a
                                :href="item.href"
                                class="text-md leading-6 text-gray-300 hover:text-white"
                                >{{ item.name }}</a
                            >
                        </li>
                    </ul>
                    <h3
                        class="mt-10 text-xl font-gothic font-semibold leading-6 tracking-wide text-white"
                    >
                        Legal
                    </h3>
                    <ul role="list" class="mt-6 space-y-4">
                        <li v-for="item in navigation.legal" :key="item.name">
                            <Link
                                :href="item.href"
                                class="text-md leading-6 text-gray-300 hover:text-white"
                                >{{ item.name }}</Link
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Bottom Footer -->
            <div
                class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 flex flex-wrap gap-4 justify-between"
            >
                <p class="text-md leading-5 text-gray-400">
                    &copy; {{ new Date().getFullYear() }} RemoteDXB.com. All
                    rights reserved.
                </p>
                <p class="text-gray-400">
                    Proudly hosted with
                    <a
                        href="https://hetzner.cloud/?ref=uN3ZHj5XwZsY"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="text-md leading-5 text-gray-400 underline underline-offset-4 hover:text-white inline-flex gap-x-1 items-center"
                    >
                        <span>Hetzner Cloud</span>
                        <ArrowTopRightOnSquareIcon
                            class="h-5 w-5 inline"
                        ></ArrowTopRightOnSquareIcon>
                    </a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { computed, defineComponent, h, inject } from "vue";
import { Link, usePage } from "@inertiajs/vue3";
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline/index.js";

const route = inject("route");

const page = usePage();
const appName = computed(() => page.props.appName);
const logoUrl = computed(() => page.props.logoUrl);

// get max 20 categories and create a navigation object
const navigationCategories = page.props.categories
    .slice(0, 20)
    .map((category) => {
        return {
            name: category.name + " jobs in Dubai",
            href: route("listings.category", { slug: category.slug }),
        };
    });

// get max 20 tags and create a navigation object
const navigationTags = page.props.tags.slice(0, 20).map((tag) => {
    return {
        name: tag.name + " jobs in Dubai",
        href: route("listings.tag", { slug: tag.slug }),
    };
});

const navigation = {
    category: navigationCategories,
    type: [
        {
            name: "Full-time jobs in Dubai",
            href: route("listings.commitment", { slug: "full-time" }),
        },
        {
            name: "Part-time jobs in Dubai",
            href: route("listings.commitment", { slug: "part-time" }),
        },
        {
            name: "Contract jobs in Dubai",
            href: route("listings.commitment", { slug: "contract" }),
        },
        {
            name: "Intern jobs in Dubai",
            href: route("listings.commitment", { slug: "internship" }),
        },
        {
            name: "Temporary jobs in Dubai",
            href: route("listings.commitment", { slug: "temporary" }),
        },
        {
            name: "Hybrid jobs in Dubai",
            href: route("listings.remote-status", { slug: "hybrid" }),
        },
        {
            name: "Completely remote jobs in Dubai",
            href: route("listings.remote-status", {
                slug: "completely-remote",
            }),
        },
    ],
    city: [
        {
            name: "Work from home jobs in Dubai",
            href: route("listings.location", { slug: "dubai" }),
        },
        {
            name: "Work from home jobs in Abu Dhabi",
            href: route("listings.location", { slug: "abu-dhabi" }),
        },
        {
            name: "Work from home jobs in Sharjah",
            href: route("listings.location", { slug: "sharjah" }),
        },
    ],
    tag: navigationTags,
    company: [
        { name: "About", href: route("about") },
        { name: "Blog", href: route("blog.index") },
        { name: "Discord", href: route("discord") },
        { name: "Contact", href: route("contact.index") },
        { name: "RSS", href: route("feeds.main") },
        { name: "API", href: route("api.listings.index") },
        { name: "Status", href: "https://status.remotedxb.com" },
    ],
    legal: [
        { name: "Privacy", href: "/privacy" },
        { name: "Terms", href: "/terms" },
    ],
    social: [
        {
            name: "Facebook",
            href: page.props.socials.facebookUrl,
            icon: defineComponent({
                render: () =>
                    h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
                        h("path", {
                            "fill-rule": "evenodd",
                            d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
                            "clip-rule": "evenodd",
                        }),
                    ]),
            }),
        },
        {
            name: "Instagram",
            href: page.props.socials.instagramUrl,
            icon: defineComponent({
                render: () =>
                    h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
                        h("path", {
                            "fill-rule": "evenodd",
                            d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
                            "clip-rule": "evenodd",
                        }),
                    ]),
            }),
        },
        {
            name: "X",
            href: page.props.socials.twitterUrl,
            icon: defineComponent({
                render: () =>
                    h(
                        "svg",
                        { fill: "currentColor", viewBox: "0 0 1200 1227" },
                        [
                            h("path", {
                                d: "M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z",
                            }),
                        ],
                    ),
            }),
        },
        {
            name: "YouTube",
            href: page.props.socials.youtubeUrl,
            icon: defineComponent({
                render: () =>
                    h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
                        h("path", {
                            "fill-rule": "evenodd",
                            d: "M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z",
                            "clip-rule": "evenodd",
                        }),
                    ]),
            }),
        },
    ],
};
</script>
